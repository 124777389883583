
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import { MemoTag } from "@/lib/models";
import { Dialog } from "@/lib/utils";
import RoundInput from "@/components/atoms/RoundInput.vue";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/SimpleDialog.vue";
import RemoveCircleIcon from "@/components/atoms/RemoveCircleIcon.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { RoundInput, RemoveCircleIcon },
  data() {
    return {
      deleted: false,
      createMemoTagName: "",
    };
  },
  methods: {
    onClickOutSide() {
      this.$close(true);
    },
    onNegativeButtonClick() {
      this.$close(true);
    },
    onChangeText(e: InputEvent) {
      e.preventDefault();
      const { target } = e;
      // targetの型を確定するとvalueを参照できる
      if (target instanceof HTMLInputElement) {
        this.createMemoTagName = target.value;
      }
    },
    onCreateTagButtonClick(e: MouseEvent) {
      this.$store.dispatch("memoModule/createMemoTag", {
        id: uuidv4(),
        name: this.createMemoTagName,
        callback: () => {
          this.createMemoTagName = "";
        },
      });
    },
    onDeleteClick(memoTag: MemoTag) {
      const confirmDialog = new Dialog(SimpleDialog);
      const data: SimpleDialogProps = {
        title: "タグを削除しますか？",
        content: `「${memoTag.name}」のタグを削除しますか？
※元に戻すことはできません。`,
        positive: "削除する",
        positiveCallback: () => this.deleteMemoTag(memoTag),
      };
      confirmDialog.show(data);
    },
    deleteMemoTag(memoTag: MemoTag) {
      const callback = () => {
        this.deleted = true;
        setTimeout(() => {
          this.deleted = false;
        }, 1000);
      };
      this.$store.dispatch("memoModule/deleteMemoTag", {
        memoTag,
        callback,
      });
    },
  },
  computed: {
    memoTags() {
      return this.$store.state.memoModule.memoTags;
    },
  },
  props: {
    negative: {
      type: String,
      default: "閉じる",
    },
  },
});

interface Data {
  deleted: boolean;
  createMemoTagName: string;
}

interface Methods {
  onClickOutSide: () => void;
  onNegativeButtonClick: () => void;
  onChangeText: (e: InputEvent) => void;
  onCreateTagButtonClick: (e: MouseEvent) => void;
  onDeleteClick: (memoTag: MemoTag) => void;
  deleteMemoTag: (memoTag: MemoTag) => void;
}

interface Computed {
  memoTags: MemoTag[];
}

interface Props {
  negative: string;
}
