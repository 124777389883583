
import Vue from "vue";
import * as lodash from "lodash";
import PopupMenu from "@/components/molecules/PopupMenu.vue";
import MemoTagEditDialog from "@/components/ui/MemoTagEditDialog.vue";
import SortIcon from "@/components/atoms/SortIcon.vue";
import { Novel } from "@/lib/models";
import { Dialog } from "@/lib/utils";
import { isAfter, isBefore } from "date-fns";
import BookIcon from "@/components/atoms/BookIcon.vue";

// typesがないので、requireで定義
const ClickOutside = require("vue-click-outside");

// ソートの種類
const SortType = {
  createdAsc: "作成日(昇順)",
  createdDesc: "作成日(降順)",
  // 作品名の更新日時は取得できるが、作品の中身まで詳細に
  // 更新をチェックしていないので、省く
  // updatedAsc: "更新日(昇順)",
  // updatedDesc: "更新日(降順)",
  titleAsc: "タイトル(昇順)",
  titleDesc: "タイトル(降順)",
} as const;

// enumは使わない方がいいみたいだからunion型にしてみた
// eslint-disable-next-line no-redeclare
type SortType = typeof SortType[keyof typeof SortType];

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: { ClickOutside },
  components: { PopupMenu, SortIcon, BookIcon },
  created() {
    // 最新の作品情報をstateに格納する
    this.$store.dispatch("novelModule/initialize");
  },
  data() {
    return {
      showSortPopupMenu: false,
      showEdtiTagModal: false,
      currentSortType: SortType.createdAsc,
    };
  },
  methods: {
    switchFragment() {
      this.showSortPopupMenu = !this.showSortPopupMenu;
    },
    closePopupMenu() {
      this.showSortPopupMenu = false;
    },
    onSelectedItem(item: SortType) {
      this.currentSortType = item;
      this.closePopupMenu();
    },
    async onCickEditMemoTagButton() {
      const dialog = new Dialog(MemoTagEditDialog);
      await dialog.show();
    },
    sortNovels(novels: Novel[]) {
      return novels.sort((a, b) => {
        const aCreatedDate = new Date(a.createdAt!);
        const bCreatedDate = new Date(b.createdAt!);

        switch (this.currentSortType) {
          case SortType.createdAsc:
            return isBefore(aCreatedDate, bCreatedDate) ? -1 : 1;
          case SortType.createdDesc:
            return isAfter(aCreatedDate, bCreatedDate) ? -1 : 1;
          case SortType.titleAsc:
            return a.title.localeCompare(b.title);
          case SortType.titleDesc:
            return b.title.localeCompare(a.title);
          default:
            return isBefore(aCreatedDate, bCreatedDate) ? -1 : 1;
        }
      });
    },
  },
  computed: {
    novels() {
      const novels: Novel[] = lodash.cloneDeep(this.$store.state.novelModule.novels);
      return this.sortNovels(novels);
    },
  },
  props: {
    sortTypes: {
      type: Array,
      default: () => Object.values(SortType),
    },
  },
});

interface Data {
  showSortPopupMenu: boolean;
  currentSortType: SortType;
}

interface Methods {
  switchFragment: () => void;
  closePopupMenu: () => void;
  onCickEditMemoTagButton: () => void;
  onSelectedItem: (item: SortType) => void;
  sortNovels: (novels: Novel[]) => Novel[];
}

interface Computed {
  novels: Novel[];
}

interface Props {
  sortTypes: string[];
}
